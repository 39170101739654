.lustcollection {
  width: 100%;
  padding: 80px 60px;
  // background: url(../assets/images/bg-1.png) center center no-repeat;
  background-size: cover;
  overflow: hidden;
  position: relative;
  span {
    font-size: 15px;
    text-transform: uppercase;
    font-weight: 600;
    margin-bottom: 40px;
    color: #fff;
  }
  h2 {
    font-size: 65px;
    text-transform: uppercase;
    font-weight: 400;
    margin-top: 40px;
    margin-bottom: 20px;
    color: #fff;
    font-family: "newyork";
  }
  p {
    color: #727272;
    text-align: center;
    font-size: 18px;
    font-family: "Lato", sans-serif;
  }
  .play_icon_section {
    display: flex;
    justify-content: flex-start;
    align-self: center;
    height: 100%;
    .play_icon1 {
      display: flex;
      justify-content: flex-start;
      align-self: center;
      margin-left: 50px;
      i {
        font-size: 70px;
        color: #fff;
      }
    }
  }
}

.btn_get_started {
  font-weight: 500;
  font-size: 14px;
  letter-spacing: 1px;
  display: inline-block;
  padding: 20px 105px;
  border-radius: 4px;
  transition: 0.5s;
  line-height: 1;
  color: #fff;
  -webkit-animation-delay: 0.8s;
  animation-delay: 0.8s;
  background: #ff1933;
  text-transform: uppercase;
  margin: 30px auto;
  &:hover {
    color: #fff;
  }
}

.videoResponsive {
  // overflow: hidden;
  // padding-bottom: 56.25%;
  position: relative;
  height: 430px;
}

.videoResponsive iframe {
  height: 415px;
  width: 100%;
}
.video_cotainer {
  height: 415px;
  width: 100%;
  iframe {
    height: 415px !important;
    width: 100% !important;
  }
}
